import React from 'react';
import {MdMail} from 'react-icons/md';
import {FaGithub, FaLinkedinIn} from 'react-icons/fa';
import {Helmet} from 'react-helmet';

export default ({id, children}) => {
    return (
        <div id={id}>
            <Helmet>
                <link
                    rel="preconnect"
                    href="https://www.google-analytics.com"
                />
                <link rel="preconnect" href="http://fonts.gstatic.com" />
                <link rel="preconnect" href="http://images.ctfassets.net" />
            </Helmet>

            <div className="relative container mx-auto">{children}</div>

            <div className="footer bg-gray-800 py-10">
                <div className="container mx-auto text-center">
                    <p className="text-white text-sm">Rodolfo Perottoni</p>

                    <div className="flex mt-4 flex-row justify-center space-between">
                        <a
                            title="Send email"
                            className="scale-up-hover-md transition-ease"
                            href="mailto:rodolfo.perottoni@gmail.com">
                            <MdMail className="text-white text-xl" />
                        </a>
                        <a
                            title="Rodolfo's LinkedIn Profile"
                            className="ml-3 scale-up-hover-md transition-ease"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/in/rodp/">
                            <FaLinkedinIn className="text-white text-xl" />
                        </a>
                        <a
                            title="Rodolfo's Github Profile"
                            className="ml-3 scale-up-hover-md transition-ease"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://github.com/rodperottoni">
                            <FaGithub className="text-white text-xl" />
                        </a>
                    </div>

                    <p className="text-white text-xs mt-4 text-gray-500">
                        Built with{' '}
                        <a
                            href="https://www.gatsbyjs.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-indigo-500">
                            Gatsby.js
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};
